import React from "react";

import { Col, Row, Image } from "antd";

import nftchart from "../../assets/images/pages/landing/nftchart.png"; // relative path to image
import tokenchart from "../../assets/images/pages/landing/tokenchart.png";
import rewardchart from "../../assets/images/pages/landing/rewardschart.png";
import nftminingpower from "../../assets/images/pages/landing/nftminingpower.png";

export default function LandingTokenomics() {
  return (
    <section
      id="tokenomics"
      className="da-landing-pricing da-py-sm-64 da-pt-96"
    >
      <div className="da-landing-container">
        <Row gutter={[32, 32]}>
          <Col span={24} className="da-text-center">
            <h2 className="h1 da-mb-4" style={{ fontFamily: "XHARP" }}>
              Tokenomics{" "}
            </h2>

            {/* <p className="da-p1-body da-mt-sm-0 da-mt-32 da-mb-0">
              Creative, Innovative, Interoperable
            </p> */}
          </Col>

          <Col span={24}>
            <Row gutter={[24, 24]} align="middle" justify="center">
              <Col xl={12} md={24} span={24}>
                <Image src={nftchart} className="img" />
              </Col>
              <Col xl={12} md={24} span={24}>
                <Image src={nftminingpower} className="img" />
              </Col>{" "}
              <Col xl={12} md={24} span={24}>
                <Image src={tokenchart} className="img" />
              </Col>{" "}
              <Col xl={12} md={24} span={24}>
                <Image src={rewardchart} className="img" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
}
