import React from "react";

import { Avatar, Col, Row } from "antd";
import { Heart, Activity, TickSquare, Swap, Star, Chart } from "react-iconly";

import logo from "../../assets/images/logo/logo.png";
import content1Image from "../../assets/images/pages/landing/content_dashboard_preview.jpg";

export default function LandingContentDashboard() {
  return (
    <section
      id="features"
      className="da-landing-content-1 da-overflow-hidden "
    >
      <div className="da-landing-container">
        <Row align="middle" >
        <Col 
          //span={24} //13
            xl={24} lg={24} md={24}
            // className="da-mt-md-120"
            style={{paddingTop: "24px", paddingBottom: "24px"}}
          >
            {/* <div
              className="da-landing-content-1-circle"
              style={{
                position: "absolute",
                left: -33,
                top: 0,
                zIndex: 20,
              }}
            >
              <img src={logo} alt="content-1-logo" />
            </div> */}

            <div className="da-landing-content-1-img" style={{paddingBottom: "64px"}}>
              <img
                src={content1Image}
                alt="Perfect Solution For Small Business"
                style={{ boxShadow: "0px 0px 25px rgba(231, 231, 231, 0.7)", borderRadius: "25px"}}
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
