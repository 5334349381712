import React from "react";

import { Avatar, Col, Row } from "antd";
import { Heart, Activity, TickSquare, Swap, Star, Chart } from "react-iconly";

import logo from "../../assets/images/logo/logo.png";
import content1Image from "../../assets/images/pages/landing/content-1black.png";

export default function LandingContent1() {
  return (
    <section
      id="features"
      className="da-landing-content-1 da-border-top-1 da-border-color-dark-80 da-overflow-hidden da-mt-sm-64 da-mt-120 da-py-sm-64 da-py-120"
    >
      <div className="da-landing-container da-mr-md-0 da-mr-120 da-mt-md-120">
        <Row gutter={32} align="middle" justify="space-between">
        {/* xl={10} lg={12} md={12} */}
          <Col xl={10} lg={10} md={24} >
            <h2 className="h1 da-mb-32" style={{ fontFamily: "XHARP" , fontSize: "32px"}}>
              Features
            </h2>

            <p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48 da-pr-96">
              A full range of web3 tools
            </p>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row align="middle">
                  <Col>
                    <Avatar
                      size={60}
                      style={{ marginRight: 10 }}
                      icon={
                        <Chart
                          set="bold"
                          style={{ width: 24, height: 24, marginLeft: 0 }}
                        />
                      }
                    />
                  </Col>

                  <Col className="da-ml-16">
                    <h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
                      Web3 Dashboard
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align="middle">
                  <Col>
                    <Avatar
                      size={60}
                      style={{ marginRight: 10 }}
                      icon={
                        <Star
                          set="bold"
                          style={{ width: 24, height: 24, marginLeft: 0 }}
                        />
                      }
                    />
                  </Col>

                  <Col className="da-ml-16">
                    <Row align="middle">
                      <Col>
                        <h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
                          DEX Explorer
                        </h3>
                      </Col>

                      {/* <Col>
                        <span
                          style={{ marginLeft: 10 }}
                          className="da-px-8 da-py-6 da-bg-warning-3 da-border-radius da-caption da-font-weight-400 da-text-color-black-100"
                        >
                          Feature
                        </span>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle">
                  <Col>
                    <Avatar
                      size={60}
                      style={{ marginRight: 10 }}
                      icon={
                        <Activity
                          set="bold"
                          style={{ width: 24, height: 24, marginLeft: 0 }}
                        />
                      }
                    />
                  </Col>

                  <Col className="da-ml-16">
                    <h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
                      NFT Wizard
                    </h3>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align="middle">
                  <Col>
                    <Avatar
                      size={60}
                      style={{ marginRight: 10 }}
                      icon={
                        <TickSquare
                          set="bold"
                          style={{ width: 24, height: 24, marginLeft: 0 }}
                        />
                      }
                    />
                  </Col>

                  <Col className="da-ml-16">
                    <Row align="middle">
                      <Col>
                        <h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
                          AI tools
                        </h3>
                      </Col>

                      {/* <Col>
                        <span
                          style={{ marginLeft: 10 }}
                          className="da-px-8 da-py-6 da-bg-success-1 da-border-radius da-caption da-font-weight-400 da-text-color-black-100"
                        >
                          NEW
                        </span>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align="middle">
                  <Col>
                    <Avatar
                      size={60}
                      style={{ marginRight: 10 }}
                      icon={
                        <Swap
                          set="bold"
                          style={{ width: 24, height: 24, marginLeft: 0 }}
                        />
                      }
                    />
                  </Col>

                  <Col className="da-ml-16">
                    <h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
                      Swap Service
                    </h3>
                  </Col>
                </Row>
              </Col>

             
              <Col span={24}>
                <Row align="middle">
                  <Col>
                    <Avatar
                      size={60}
                      style={{ marginRight: 10 }}
                      icon={
                        <Heart
                          set="bold"
                          style={{ width: 24, height: 24, marginLeft: 0 }}
                        />
                      }
                    />
                  </Col>

                  <Col className="da-ml-16">
                    <Row align="middle">
                      <Col>
                        <h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
                          Analytics
                        </h3>
                      </Col>

                      {/* <Col>
                        <span
                          style={{ marginLeft: 10 }}
                          className="da-px-8 da-py-6 da-bg-danger-3 da-border-radius da-caption da-font-weight-400 da-text-color-black-100"
                        >
                          Soon
                        </span>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col
            xl={14}
            lg={14}
            md={24}
            //span={14}
            className="da-mt-md-120"
          >
            {/* <div
              className="da-landing-content-1-circle"
              style={{
                position: "absolute",
                left: -33,
                top: 0,
                zIndex: 20,
              }}
            >
              <img src={logo} alt="content-1-logo" />
            </div> */}

            <div >
              <img
                src={content1Image}
                alt="Perfect Solution For Small Business"
                style={{ boxShadow: "0px 0px 25px rgba(231, 231, 231, 0.7)", borderRadius: "25px" }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
