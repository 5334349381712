import React from "react";

import LandingHeader from "./header";
import LandingBanner from "./banner";
import LandingTechnologies from "./technologies";
import LandingAboutUs from "./about";
import LandingContent1 from "./content-1";
import LandingContent2 from "./content-2";
import LandingTokenIntro from "./tokenIntro";
import LandingTokenomics from "./tokenomics";
import LandingFooter from "./footer";
import Roadmap from "./Roadmap";
import LandingContentDashboard from "./content-dashboard";
import LandingBannerFooter from "./banner_footer";

export default function Landing() {
  return (
    <div className="da-landing da-bg-black-0 da-bg-dark-90 ">
      <LandingHeader />

      <LandingBanner />

      <LandingAboutUs />

      {/* <LandingTechnologies /> */}

      <LandingContent1 />
      
      <LandingContentDashboard />

      {/* <LandingContent2 /> */}

      <LandingTokenIntro />

      <LandingTokenomics />

      {/* <Roadmap /> */}

      <LandingBannerFooter />

      <LandingFooter />
    </div>
  );
}
