import React from "react";

import { Button, Col, Row, Space } from "antd";

export default function LandingFooter() {
  return (
    <footer className="da-position-relative da-bg-black-20 da-bg-dark-100 da-pt-sm-64 da-pt-120 da-pb-24 da-overflow-hidden">
      <div className="da-landing-footer-circle da-bg-color-black-60 da-bg-color-dark-40"></div>

      <div className="da-landing-container">
        <Row justify="space-between" className="da-mb-sm-64 da-mb-120">
          <Col lg={8} md={16} span={24}>
            <p className="h5 da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-32">
              Agnostic Yield Aggregator and Metaverse Bridge
            </p>

            <Button
              className="da-bg-black-100 da-text-color-black-0"
              onClick={() =>
                (window.location = "mailto:contact@prisma.farm?subject=Contact")
              }
            >
              Ask Question
            </Button>
          </Col>

          <Col lg={14} span={24} className="da-mt-md-64">
            <Row>
              <Col flex="1 0 0">
                <span className="da-p1-body da-font-weight-500 da-mb-16">
                  COMMUNITY
                </span>

                <a
                  href="https://twitter.com/prisma_finance"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Twitter
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.com/invite/eFXeZftkH8"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Discord
                </a>
                <a
                  target="_blank "
                  href="https://github.com/Prisma-Finance"
                  rel="noopener noreferrer"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Github
                </a>
                <a
                  target="_blank "
                  href="https://diamondapp.com/u/Prisma_Finance"
                  rel="noopener noreferrer"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Diamond (DeSo)
                </a>
                <a
                  target="_blank "
                  href="https://bbs.market/PRISMA"
                  rel="noopener noreferrer"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  BBS
                </a>
                <a
                  target="_blank "
                  href="https://medium.com/@prismafinancecoin"
                  rel="noopener noreferrer"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Medium
                </a>
              </Col>

              <Col flex="1 0 0">
                <span className="da-p1-body da-font-weight-500 da-mb-16">
                  HOME
                </span>

                <a
                  href="#about"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Home
                </a>
  {/*               <a
                  href="#technologies"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Technologies
                </a> */}
                <a
                  href="#features"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Features
                </a>
              
                <a
                  href="#intro"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Introduction
                </a>
                <a
                  href="#tokenomics"
                  className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
                >
                  Tokenomics
                </a>
              </Col>

              <Col flex="0 0 260px" className="da-mt-sm-32">
                <span className="da-p1-body da-font-weight-500 da-mb-16">
                  CONTACTS
                </span>

                <p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-my-16">
                  Feel free to get in touch with us <br />
                </p>

                <a href="mailto:contact@prisma.farm?subject=Contact">
                  contact@prisma.farm
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          align="middle"
          justify="space-between"
          className="da-landing-footer-copyright da-border-top-1 da-border-color-dark-80 da-pt-24 da-mt-24"
        >
          <Col>
            <p className="da-p1-body da-mb-sm-16 da-mb-0">
              © PRISMA 2024, All Rights Reserved.
            </p>
          </Col>
          <Col>
            <Row align="middle" justify="space-between">
              <Col>
                <p className="da-p1-body da-mb-sm-16 da-mb-0 da-ml-10">
                  Built with Moralis
                </p>
              </Col>
              <Col>
                <p
                  className="da-p1-body da-mb-sm-16 da-mb-0"
                  style={{ marginLeft: 10 }}
                >
                  Hosted on IPFS
                </p>
              </Col>
              <Col>
                <p
                  className="da-p1-body da-mb-sm-16 da-mb-0"
                  style={{ marginLeft: 10 }}
                >
                  Powered by AI, web3 and Unstoppable Domains{" "}
                </p>
              </Col>
            </Row>
          </Col>

          <Col></Col>
          <Col>
            <Row align="middle">
              <Col>
                <a
                  href="https://twitter.com/prisma_finance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="da-fill-primary-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    width="52px"
                    height="52px"
                  >
                    <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
                  </svg>
                </a>
              </Col>

              <Col className="da-ml-18">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.com/invite/eFXeZftkH8"
                >
                  <svg
                    className="da-fill-primary-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="62px"
                    height="62px"
                    style={{ marginLeft: 16, marginTop: 12 }}
                  >
                    <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                  </svg>
                </a>
              </Col>

              <Col className="da-ml-18">
                <a
                  href="https://github.com/Prisma-Finance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="da-fill-primary-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="62px"
                    height="62px"
                    style={{ marginLeft: 5, marginTop: 12 }}
                  >
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                  </svg>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
