import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "../../assets/images/pages/landing/Logo_round_long.png";

export default function MenuLogo(props) {
  const customise = useSelector((state) => state.customise);
  return (
    <Link
      to="/"
      className="da-header-logo da-d-flex da-align-items-end"
      onClick={props.onClose}
    >
      <img className="da-logo" src={logo} alt="logo" style={{padding: "2px"}}/>

      {customise.theme === "light" ? (
        <span
          className="h3 d-font-weight-800 da-text-color-primary-5 da-mb-6"
          style={{ fontFamily: "XHARP" }}
        >
          PRISMA
        </span>
      ) : (
        <span
          className="h3 d-font-weight-800 da-text-color-primary-4 da-mb-6"
          style={{ fontFamily: "XHARP" }}
        >
          PRISMA
        </span>
      )}

      {/* <span
        className="da-p1-body da-font-weight-500 da-text-color-black-40 da-mb-16 da-ml-4"
        style={{
          letterSpacing: -1.5,
        }}
      >
        v.{themeConfig.version}
      </span> */}
    </Link>
  );
}
