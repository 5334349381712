import React from "react";

import bannerImage from "../../assets/images/pages/landing/opensea_banner_transparent.png";
import logo from "../../assets/images/logo/logo.png";

export default function LandingBannerFooter() {
  return (
    <section className="da-landing-hero da-pt-16" >
      {/* <div className="da-landing-hero-img da-mt-96" > */}
        {/* <div
          className="da-landing-hero-rectangle"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          className="da-landing-hero-circle"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div> */}

        {/* <div className="da-landing-hero-img-container" style={{ backgroundColor: "black", width: "100%" }}> */}
          <div
            
            style={{ backgroundImage: "url(" + bannerImage + ")", backgroundPosition: "center",  backgroundRepeat: "no-repeat", height: "400px", width: "100%", backgroundSize: "cover", }}
          ></div>
        {/* </div> */}
      {/* </div> */}
    </section>
  );
}
