import React from "react";

import bannerImage from "../../assets/images/pages/landing/Logo_effekt_double4.gif";
import logo from "../../assets/images/logo/logo.png";

import { Col, Row } from "antd";

import Background from "../../assets/images/gif/Prism_light.gif";

export default function LangingBanner() {
  return (
    <section className="da-landing-hero da-position-relative da-overflow-hidden"  style={{ height: "766px", width: "100%" }} >
      {/**/}
      <div className="da-landing-hero-img-container bg-transparent"  style={{ fontFamily: "XHARP", marginTop: "-30px" }}>
        
         <div
        className="da-landing-container-background"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "766px",
          width: "100%",
          position: "absolute",
          // transform: "rotate(180deg)",
        }}
      >

<div className="da-landing-hero-title da-text-center da-mt-64 da-px-24" >
        <h1 className="da-mb-sm-24 da-mb-32" style={{ fontFamily: "XHARP", marginTop: "300px" }}>
          Agnostic Aggregator Analytics
        </h1>

        <p className="h4 da-font-weight-400 da-text-color-black-60 ">
          Protocol scanning and analytics  <br></br>  EVM and IBC compatible <br></br>  AI powered
        </p>
      </div>
      </div>

     </div>
     {/* focusing on simplicity and user
          experience, security and privacy, */}

      {/* <div
          className="da-landing-hero-rectangle"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          className="da-landing-hero-circle"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div> */}
      {/* <div
            className="da-landing-hero-img-item"
            style={{ backgroundImage: "url(" + bannerImage + ")" }}
          ></div> */}

      {/* <div className="da-landing-hero-img-left">
        <div className="da-landing-hero-img-emoji">🖖</div>

        <div className="da-landing-hero-img-emoji">
          <img src={emoji1} alt="Emoji" />
        </div>

        <div className="da-landing-hero-img-emoji">😎</div>

        <div className="da-landing-hero-img-emoji">
          <img src={emoji2} alt="Emoji" />
        </div>
      </div>

      <div className="da-landing-hero-img-right">
        <div className="da-landing-hero-img-emoji">🌍</div>

        <div className="da-landing-hero-img-emoji">😇</div>

        <div className="da-landing-hero-img-emoji">
          <img src={emoji3} alt="Emoji" />
        </div>

        <div className="da-landing-hero-img-emoji">
          <img src={emoji4} alt="Emoji" />
        </div>
      </div> */}
    </section>
  );
}
