import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ConfigProvider } from "antd";

import { theme } from "./redux/customise/customiseActions";
import FullLayout from "./layout/FullLayout";
import HomePage from "./view/landing";

export default function App() {
  const dispatch = useDispatch();
  const customise = useSelector((state) => state.customise);
  const Layouts = { FullLayout };

  // Dark Mode
  useEffect(() => {
    document.querySelector("body").classList.add(customise.theme);
    dispatch(theme(customise.theme));
  }, []);

  return (
    <ConfigProvider direction={customise.direction}>
      <Layouts.FullLayout>
        <HomePage />
      </Layouts.FullLayout>
    </ConfigProvider>
  );
}
