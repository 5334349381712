import React from "react";

import { useSelector } from "react-redux";

import { Button, Col, Menu, Row, Dropdown } from "antd";
import { RiMenuFill } from "react-icons/ri";

import MenuLogo from "./logo";

export default function LandingHeader() {
  // Redux
  const customise = useSelector((state) => state.customise);

  const loginButton = (
    <Button
      type="primary"
      className="da-px-sm-16 da-py-sm-8 da-px-32 da-py-16 da-ml-sm-0 da-ml-8"
      onClick={() => window.open("https://app.prisma.farm", "_blank")}
    >
      Launch Dapp
    </Button>
  );
  const nftCollectionButton = (
    <Button
      type="secondary"
      className="da-px-sm-16 da-py-sm-8 da-px-32 da-py-16 da-ml-sm-8 da-text-color-black-80 da-text-color-dark-30"
      onClick={() => window.open("https://minter.prisma.farm", "_blank")}
    >
      Minter
    </Button>
  );

  const menuItems = (
    <>
      <Menu.Item key={0} className="da-border-radius">
        <a href="#about">Home</a>
      </Menu.Item>

{/*       <Menu.Item key={1} className="da-border-radius">
        <a href="#technologies">Technologies</a>
      </Menu.Item> */}

      <Menu.Item key={3} className="da-border-radius">
        <a href="#features">Features</a>
      </Menu.Item>
      {/* <Menu.Item key={4} className="da-border-radius">
        <a href="#launchpad">Launchpad</a>
      </Menu.Item> */}
      <Menu.Item key={5} className="da-border-radius">
        <a href="#intro">Introduction</a>
      </Menu.Item>
      <Menu.Item key={6} className="da-border-radius">
        <a href="#tokenomics">Tokenomics</a>
      </Menu.Item>
    </>
  );

  return (
    <header className="da-my-8 da-bg-black-10 da-bg-dark-100">
      <div className="da-landing-container">
        <Row align="middle" justify="space-between">
          <Col>
            <MenuLogo />
          </Col>

          <Col className="da-landing-header-mobile-button">
            <Dropdown
              placement="bottomRight"
              trigger="click"
              overlay={
                <Menu
                  mode="vertical"
                  theme={customise.theme == "light" ? "light" : "dark"}
                  className="da-bg-dark-90"
                >
                  {menuItems}

                  <Menu.Item key={3}>
                    <Row justify="space-between">
                      <Col>{loginButton}</Col>
                      <Col>{nftCollectionButton}</Col>
                    </Row>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                type="text"
                icon={
                  <RiMenuFill
                    size={24}
                    className="remix-icon da-text-color-black-80 da-text-color-dark-30"
                  />
                }
              />
            </Dropdown>
          </Col>

          <Col flex="1 0 0" className="da-px-24 da-landing-header-menu">
            <Menu
              mode="horizontal"
              className="da-d-flex-full-center da-bg-black-10 da-bg-dark-100"
              theme={customise.theme == "light" ? "light" : "dark"}
            >
              {menuItems}
            </Menu>
          </Col>

          <Col className="da-landing-header-buttons">
            {nftCollectionButton}
            {loginButton}
          </Col>
        </Row>
      </div>
    </header>
  );
}
