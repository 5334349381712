import React from "react";

import { Col, Row } from "antd";

import Background from "../../assets/images/gif/Prism2_light.gif";

export default function LandingTokenIntro() {
  const peopleValues = [
    {
      comment:
        "Genesis Prisma NFTs building a fundamental base of a fully community-owned web3 platform and ecosystem. Equipped with various rates of mining power can earn a new revolutionary and deflationary token by stakedrop. Prisma platform token also represents the ecosystem governance. Incentivizing time lock staking and liquidity by yield generating underlying assets rewards strong holders the most. Aiming to be the turnstile of decentralized services, providing and innovating with new standards.",
    },
  ];

  const peopleListMap = peopleValues.map((item, index) => (
    <Col key={index} span={24}>
      <div
        id="intro"
        className="da-landing-people-item da-position-relative da-border-radius da-bg-black-0 da-bg-dark-90 da-p-sm-16 da-p-24"
        style={{ boxShadow: "0px 0px 25px rgba(231, 231, 231, 0.7)" }}
      >
        {/* <div className="da-landing-people-item-twitter">
          <svg
            fill="#21C6DD"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="16px"
            height="16px"
          >
            <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
          </svg>
        </div> */}

        <Row align="middle">
          <Col>
            <img
              src={item.avatar}
              alt={item.name}
              className="da-landing-people-item-img"
            />
          </Col>

          <Col className="da-landing-people-item-title">
            <span className="da-d-block h5 da-font-weight-400">
              {item.name}
            </span>
            <span className="da-d-block da-caption da-font-weight-400 da-text-color-black-60 da-text-color-dark-40">
              {item.title}
            </span>
          </Col>
        </Row>

        <p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-mb-0">
          {item.comment}
        </p>
      </div>
    </Col>
  ));

  return (
    <section className="da-landing-people da-py-sm-64 da-pt-96 da-pb-120 da-bg-black-10 da-bg-dark-100 da-position-relative da-overflow-hidden">
      <div
        className="da-landing-container-background"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "100%",
          position: "absolute",
          transform: "rotate(180deg)",
          top: 0,
        }}
      ></div>

      <div className="da-landing-container">
        <Row justify="center">
          <Col xl={10} lg={15} md={18} span={24} className="da-text-center">
            <h2 className="h1 da-mb-4" style={{ fontFamily: "XHARP", fontSize: "38px" }}>
              Neon Prism NFTs
            </h2>

            <p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-sm-0 da-mt-32 da-mb-0">
              Creative, Innovative, Interoperable
            </p>
          </Col>

          <Col span={24} className="da-mt-64">
            <Row gutter={[32, 32]}>{peopleListMap}</Row>
          </Col>
        </Row>
      </div>
    </section>
  );
}
