import React from "react";

import Slider from "react-slick";
import { Avatar, Button, Col, Row, Divider } from "antd";

import PrismaRed from "../../assets/images/gif/PrismRed.gif";
import PrimsaBlue from "../../assets/images/gif/PrismBlue.gif";
import PrimsaYellow from "../../assets/images/gif/PrismYellow.gif";
import PrimsaGreen from "../../assets/images/gif/PrismGreen.gif";

export default function LandingAboutUs() {
  const featuresCard = [
    {
      icon: PrismaRed,
      title: "Opal",
      subTitle:
        "Interoperable ERC-721",
    },
    {
      icon: PrimsaBlue,
      title: "Saphir",
      subTitle:
        "Interoperable ERC-721",
    },

    {
      icon: PrimsaGreen,
      title: "Rubin",
      subTitle:
        "Interoperable ERC-721",
    },
    {
      icon: PrimsaYellow,
      title: "Smaragd",
      subTitle:
        "Interoperable ERC-721",
    },
  ];

  const featuresCardMap = featuresCard.map((value, index) => (
    <div key={index} className="da-px-sm-8 da-px-16" style={{ height: 500 }}>
      <div className="da-border-radius da-bg-black-10 da-bg-dark-100 da-p-18">
        <Row gutter={[20]}>
          <Col span={24}>
            <Avatar
              className="da-bg-black-0 da-bg-dark-90"
              size={160}
              src={value.icon}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Col>

          <Col span={24}>
            <h4
              className="da-font-weight-500 da-mb-8"
              style={{ fontFamily: "XHARP" }}
            >
              {value.title}
            </h4>
            <p className="h5 da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
              {value.subTitle}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  ));

  return (
    <section
      id="about"
      className="da-landing-about da-pt-64 da-pb-sm-64 da-pb-12"
    >
      <Row align="middle" justify="center">
        <Col
          xl={10}
          lg={14}
          md={16}
          span={24}
          className="da-px-16 da-text-center da-mb-sm-64 da-mb-12"
        >
          <h2 className="h1 da-mb-32" style={{ fontFamily: "XHARP" }}>
            INTEROPERABLE
          </h2>

          <p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30">
            Agnostic Interface Layer 
          </p>
          <p className="h5 da-font-weight-400 da-text-color-black-80">
          Building a mediation platform, dex analytics and AI NFT wizard on top of a generative yield ecosystem based on agnostic render and file storage protocols utilizing the next-gen decentralized and disruptive web3 tech
          </p>
          <Divider />
        </Col>
        <Col span={24} className="da-px-16 da-text-center">
          <h2 className="h3 da-mb-32" style={{ fontFamily: "XHARP" }}>
            CHOOSE PRISMA
          </h2>
        </Col>
        <Col span={24}>
          <Slider
            dots={false}
            infinite={true}
            slidesToShow={3}
            slidesToScroll={1}
            arrows={false}
            speed={1800}
            autoplay={true}
            autoplaySpeed={6000}
            centerMode={true}
            centerPadding={230}
            responsive={[
              {
                breakpoint: 1370,
                settings: {
                  centerPadding: 150,
                },
              },
              {
                breakpoint: 1199,
                settings: {
                  centerPadding: 80,
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  centerPadding: 24,
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {featuresCardMap}
          </Slider>
        </Col>

        <Col
          span={24}
          className="da-px-16 da-text-center da-mt-sm-32 da-mt-64"
        >
          <Button
            type="primary"
            onClick={() => window.open("https://app.prisma.farm", "_blank")}
            style={{width: "360px", height: "80px", fontSize: "22px", marginTop: "36px"}}>
            Launch Dapp
          </Button>
        </Col>
      </Row>
    </section>
  );
}
